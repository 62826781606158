@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700|Libre+Franklin:100,100i,300,300i,400,400i,500,500i,600,600i,700,700i");

.App {
  font-family: "Libre Franklin", sans-serif;
  font-size: 14px;
}

.monospace {
  font-family: "Inconsolata", monospace;
}

/* No Padding/Margin hack */
.no-padding {
  padding: 0;
}

.nav-tab {
  text-decoration: none;
}

.dropdown-item {
  font-size: 14px;
}

.no-margin {
  margin: 0;
}

.checkbox label,
.radio label {
  padding-left: 10px;
  min-height: 20px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.form-control {
  font-size: 14px;
}

.navbar-text {
  margin-left: 15px;
}

pre {
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  display: block;
  font-size: 13px;
  line-height: 1.42857143;
  margin: 0 0 10px;
  padding: 9.5px;
  word-break: break-all;
}

.h4 {
  font-size: 18px;
  font-weight: 700;
}

input[type="radio"] {
  margin-right: 10px;
}

.form-check-input {
  width: 13px;
  height: 13px;
}

.form-check-input:checked {
  border-color: #31a7ff;
  background-color: #31a7ff;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0;
  border-color: #bfbfbf;
}

.form-label {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.radio + .radio {
  margin-top: -5px;
}

.radio {
  margin-bottom: 10px;
  margin-top: 10px;
}
